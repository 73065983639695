<template>
  <div class="stack">
    <div
      class="stack__child"
      v-for="(stack, index) in element.children"
      :key="index"
    >
      <div
        class="image-container"
        v-if="stack.type === 'image'"
        :style="getStyle(stack)"
      >
        <div
          class="image-container__remove-bg"
          v-if="stack.image.removeBgInProgress === true"
        >
          <img
            :src="require(`../assets/icons/removebg-progress.gif`)"
            aria-label="progress"
          />
        </div>
        <div
          v-if="!stack.image.src.includes('img/image-placeholder')"
          :style="getImageStyle(stack)"
          class="image-container__image"
        />

        <!-- <img v-else :src="imagePlaceholder" /> -->
        <div
          v-else
          :style="getImagePlaceHolderStyle(stack)"
          class="image-container__image"
        />
      </div>

      <div
        v-if="stack.type === 'qr'"
        class="stack__qr"
        :style="getStyle(stack)"
      >
        <qrcode-vue
          :value="stack.url"
          :size="getStyle(stack).width.replace('px', '') - 48"
          :id="`qr_${path.join('_')}_${index}`"
        ></qrcode-vue>
      </div>

      <div
        class="image-container"
        :class="stack.classes.logoType"
        v-if="stack.type === 'logo'"
        :style="getStyle(stack)"
      >
        <div :style="getLogoStyle(stack)" class="image-container__logo" />
      </div>

      <div
        class="video-container"
        v-if="stack.type === 'video'"
        :style="getStyle(stack)"
      >
        <video
          v-if="!checkIfPlaceholder(stack) && !stack.video.url"
          :src="stack.video.src"
          :class="stack.classes.imageStyles"
          muted
          crossorigin="anonymous"
        />

        <video
          :class="stack.classes.imageStyles"
          v-else-if="stack.video.url"
          :src="stack.video.url"
          muted
          crossorigin="anonymous"
        />

        <video
          :class="stack.classes.imageStyles"
          v-if="checkIfPlaceholder(stack)"
          :poster="videoPlaceholder"
          :src="videoPlaceholder"
          muted
          crossorigin="anonymous"
        />
      </div>

      <div
        v-if="stack.type === 'text'"
        :style="getTextStyle(stack)"
        :class="stack.classes.textStyles"
        class="editor-template-content__text-element"
      >
        <span v-html="stack.text"></span>
      </div>

      <div
        v-if="element.type === 'shape'"
        :style="getStyle(element)"
        class="editor-template-content__shape-element"
      >
        <div class="editor-template-content__shape" v-html="element.text" />
      </div>

      <div v-if="stack.type === 'stack'">
        <Stack
          :element="stack"
          :style="getStackStyle(stack)"
          :path="[...path, index]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addPx } from "../utils/utils";
import QrcodeVue from "qrcode.vue";

export default {
  name: "Stack",
  components: {
    QrcodeVue
  },
  props: {
    path: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentBrand: "getParentBrand",
      rootBrand: "getRootBrand"
    }),
    isSlidePreview() {
      return this.$route.name === "SlidePreview";
    },
    brand() {
      if (this.isSlidePreview) {
        if (this.$route.params.teamType === "p") {
          return this.parentBrand;
        } else if (this.$route.params.teamType === "r") {
          return this.rootBrand;
        }
      }

      if (
        this.parentTeam &&
        !this.parentTeam.children[this.team.id].brandManager
      ) {
        return this.parentBrand;
      } else {
        return this.team.brand ? this.team.brand : {};
      }
    },
    textStyles() {
      return this.brand?.textStyles;
    },
    videoPlaceholder() {
      return require("../assets/img/video-placeholder.png");
    },
    imagePlaceholder() {
      return require("../assets/img/image-placeholder.png");
    },
    logoPlaceholder() {
      return require("../assets/icons/logo-placeholder.svg");
    }
  },
  methods: {
    getStyle(element) {
      return {
        ...element.css[0],
        ...element.css[this.orientation]
      };
    },
    getTextStyle(element) {
      let styles = {
        fontSize: this.textStyles[element.classes.textStyles]?.fontSize
          ? this.textStyles[element.classes.textStyles].fontSize + "px"
          : undefined,
        lineHeight: this.textStyles[element.classes.textStyles]?.lineHeight
          ? this.textStyles[element.classes.textStyles].lineHeight + "px"
          : undefined,
        textTransform: this.textStyles[element.classes.textStyles]
          ?.textTransform
          ? this.textStyles[element.classes.textStyles].textTransform
          : undefined,
        letterSpacing: this.textStyles[element.classes.textStyles]
          ?.letterSpacing
          ? this.textStyles[element.classes.textStyles].letterSpacing
          : undefined
      };

      return {
        ...element.css[0],
        ...element.css[this.orientation],
        fontSize: addPx(
          element.css[this.orientation]?.fontSize
            ? element.css[this.orientation].fontSize
            : element.css[0].fontSize
            ? element.css[0].fontSize
            : styles.fontSize
        ),
        lineHeight: element.css[this.orientation]?.lineHeight
          ? element.css[this.orientation].lineHeight
          : element.css[0].lineHeight
          ? element.css[0].lineHeight
          : styles.lineHeight,
        textTransform: element.css[this.orientation]?.textTransform
          ? element.css[this.orientation].textTransform
          : element.css[0].textTransform
          ? element.css[0].textTransform
          : styles.textTransform,
        letterSpacing: element.css[this.orientation]?.letterSpacing
          ? element.css[this.orientation].letterSpacing
          : element.css[0].letterSpacing
          ? element.css[0].letterSpacing
          : styles.letterSpacing
      };
    },
    getStackStyle(element) {
      return { ...this.getStyle(element), ...this.getTextStyle(element) };
    },
    getImagePlaceHolderStyle(element) {
      return `background-image: url(${this.imagePlaceholder}); background-size: ${element.classes.imageStyles}`;
    },
    getImageStyle(element) {
      return `background-image: url(${element.image.src}); background-size: ${element.classes.imageStyles}`;
    },
    getLogoStyle(element) {
      if (element.classes.logoType === "bright") {
        if (this.brand && this.brand.logo) {
          return `background-image: url(${this.brand.logo});`;
        } else if (this.brand && this.brand.logoDark) {
          return `background-image: url(${this.brand.logoDark});`;
        } else {
          return `background-image: url(${this.logoPlaceholder});`;
        }
      } else if (element.classes.logoType === "dark") {
        if (this.brand && this.brand.logoDark) {
          return `background-image: url(${this.brand.logoDark});`;
        } else if (this.brand && this.brand.logo) {
          return `background-image: url(${this.brand.logo});`;
        } else {
          return `background-image: url(${this.logoPlaceholder});`;
        }
      }
    },
    checkIfPlaceholder(element) {
      if (typeof element.video.src === "string") {
        return element.video.src.includes("img/video-placeholder");
      }
    }
  }
};
</script>

<style lang="scss">
.stack {
  &__child {
    margin: unset !important;
  }

  &__qr {
    background-color: #fff !important;
  }
}
</style>
